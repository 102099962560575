import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="min-h-screen">
        <div className="h-[105px] lg:h-[160px] bg-dark-green"></div>

        <iframe
          src="https://matter-intake.com.au/create/40374748-cbb6-463c-a90c-71d7c98ccf70"
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            border: "none",
          }}
          className="min-h-[1000px] lg:min-h-[max(100vh,1000px)]"
        ></iframe>
      </div>
    </Layout>
  );
};

export default ContactPage;
